img {
    max-height: 240px;
    margin: 0 auto;
  }
  
  .app-container {
    width: 500px;
    margin: 30px auto;
  }
  
  .clear {
    clear: both;
  }
  
  .details,
  .title {
    text-align: center;
  }
  
  .error {
    margin-top: 4px;
    color: red;
  }
  
  .has-error {
    border: 1px dotted red;
  }
  
  .image-container {
    align-items: center;
    display: flex;
    width: 85%;
    height: 80%;
    float: left;
    margin: 15px 10px 10px 37px;
    text-align: center;
  }
  
  .preview-container {
    align-content: center;
    height: 335px;
    width: 500px;
    margin-bottom: 40px;
  }
  
  .placeholder-preview {
    cursor: pointer;
  }
  
  .placeholder-preview,
  .render-preview {
    text-align: center;
    background-color: #efebeb;
    height: 100%;
    width: auto;
    border-radius: 5px;
  }
  
  .upload-container {
    cursor: pointer;
    height: auto;
    width: max-content;
  }
  