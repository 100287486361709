.modal-wrapper {
    background: white;
    border: 1px solid #d0cccc;
    box-shadow: 0 5px 8px 0 rgba(0,0,0,0.2), 0 7px 20px 0 rgba(0,0,0,0.17);
    margin: 100px auto 0;
    transition: all .8s;
    width: 30%;
    border-radius: 10px;
    }
    
    .modal-header {
    background: #e2e2e2;
    height: 40px;
    line-height: 40px;
    padding: 5px 20px;
    text-align: center;
    }
    
    .modal-header h2 {
    float: left;
    margin: 0;
    padding: 0;
    }
    
    .modal-body {
    padding: 10px 15px;
    text-align: center;
    height: 100%;
    }
    
    .modal-footer {
    background: #e2e2e2;
    height: 35px;
    padding: 15px;
    width: 100%;
    font-size: 10px;
    }
    
    .close-modal-btn {
    color: white;
    cursor: pointer;
    float: right;
    font-size: 30px;
    margin: 0;
    }
    
    .close-modal-btn:hover {
    color: black;
    }
    
    .btn-cancel {
    background-color: #b71c1c;
    float: left;
    }
    
    .btn-continue {
    background-color: #1b5e20;
    float: right;
    }
    
    .modal-image {
        width: 50%;
        height: 50%;
        justify-content: center;
        align-items: center;
    }

    .back-shed {
    background-color: rgba(48, 49, 48, 0.42);
    height: 100%;
    position: fixed;
    transition: all 1.3s;
    width: 100%;
    }
    
    .open-modal-btn {
    margin: 15px;
    padding: 10px;
    }

    /*Sobre-escribiendo z-index para el swal*/
.swal2-container {
    z-index: 999999;
}

  div.swal2-content{
    font-size: 14px
  }